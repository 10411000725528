// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-templates-about-jsx": () => import("./../../../src/templates/about.jsx" /* webpackChunkName: "component---src-templates-about-jsx" */),
  "component---src-templates-checkout-jsx": () => import("./../../../src/templates/checkout.jsx" /* webpackChunkName: "component---src-templates-checkout-jsx" */),
  "component---src-templates-contact-jsx": () => import("./../../../src/templates/contact.jsx" /* webpackChunkName: "component---src-templates-contact-jsx" */),
  "component---src-templates-imprint-jsx": () => import("./../../../src/templates/imprint.jsx" /* webpackChunkName: "component---src-templates-imprint-jsx" */),
  "component---src-templates-index-jsx": () => import("./../../../src/templates/index.jsx" /* webpackChunkName: "component---src-templates-index-jsx" */),
  "component---src-templates-privacy-jsx": () => import("./../../../src/templates/privacy.jsx" /* webpackChunkName: "component---src-templates-privacy-jsx" */),
  "component---src-templates-product-jsx": () => import("./../../../src/templates/product.jsx" /* webpackChunkName: "component---src-templates-product-jsx" */),
  "component---src-templates-products-jsx": () => import("./../../../src/templates/products.jsx" /* webpackChunkName: "component---src-templates-products-jsx" */)
}

